<script lang="ts">
	import '../theme.postcss';
	// Finally, your application's global stylesheet (sometimes labeled 'app.css')
	import '../app.postcss';
	import Footer from '$lib/components/Footer.svelte';
	import '@fontsource/pacifico';
	import Header from '$lib/components/Header.svelte';
</script>

<div class="wrapper">
	<header
		class="bg-surface-100-800-token mx-0 border-b border-slate-500 pl-4 pt-2 text-4xl sm:pb-0 lg:pb-1 lg:text-6xl"
	>
		<Header />
	</header>
	<section>
		<slot />
	</section>
	<footer class="bg-surface-100-800-token mx-0 border-t border-slate-500 py-2">
		<Footer />
	</footer>
</div>

<style lang="postcss">
	header,
	footer {
		color: rgb(var(--color-primary-100));
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	header,
	footer {
		flex: 0;
	}
	section {
		flex-grow: 1;
	}
</style>
