<div class="header">
	<div class="leftside">
		<img
			src="/icon-96x96.png"
			sizes="(min-width: 1024px) 96px, 56px"
			srcset="/icon-56x56.png 56w, /icon-96x96.png 96w"
			alt="icon"
		/>
		<a href="/">Lorem Quotesum</a>
	</div>
	<p>Instead of boring dummy text in latin, use funny TV & movie quotes for testing</p>
</div>

<style lang="postcss">
	.header {
		@apply flex flex-row justify-between items-center gap-2;
	}
	.leftside {
		@apply flex-shrink-0 flex flex-row items-center gap-2 lg:gap-4 z-10;
	}
	img {
		@apply max-h-8 lg:max-h-14;
	}
	a {
		font-family: 'Pacifico', sans-serif;
	}
	p {
		@apply text-sm pr-2 flex-shrink text-right hidden sm:block lg:text-base;
	}
</style>
