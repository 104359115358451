<div class="footer flex flex-row justify-between text-sm lg:text-base">
	<div class="ml-4">
		<p>Version {import.meta.env.VITE_APPVERSION}</p>
		<p>
			&copy; {new Date().getFullYear()} by
			<a href="https://fonner.dev/" target="_blank" rel="noreferrer">Fonner Development</a>
		</p>
	</div>
	<div class="mr-4 text-right">
		<p class="hover:underline"><a href="/privacy">Privacy Policy</a></p>
		<p class="hover:underline"><a href="/terms">Terms of Service</a></p>
	</div>
</div>

<style lang="postcss">
	a {
		@apply hover:underline;
	}
</style>
