import '../app.css';

// Ensures all pages under this layout (which is all of them) are statically prerendered at build time
export const prerender = true;

// Allows client side routing. Necessary for page transitions and link prefetching; change to false if you prefer ordinary routing without JS
export const csr = true;

// Firebase hosting does not render /a.html upon receiving a request for /a so we set trailingSlash: 'always' to create /a/index.html instead.
export const trailingSlash = 'always';
